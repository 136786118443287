



// import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { Navbar, Nav, Form, FormControl, Button, NavDropdown, Container, Modal, Dropdown } from 'react-bootstrap';
// import { getAllCities } from '../../api'; // Assuming you have this API call
// import LoginRegister from '../LoginRegister/LoginRegister';
// import { useGlobalState } from '../../contexts/GlobalStateContext';
// import './NavBar.css';

// const UpperNavBar = () => {
//   const [cities, setCities] = useState([]);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [showModal, setShowModal] = useState(false);
//   const [isLoggedIn, setIsLoggedIn] = useState(false); // Track login status
//   const { selectedCity, setSelectedCity } = useGlobalState();
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchCities = async () => {
//       try {
//         const data = await getAllCities();
//         setCities(data.data);
//       } catch (error) {
//         console.error('Error fetching cities:', error);
//       }
//     };

//     fetchCities();
//   }, []);

//   const handleCitySelect = (city) => {
//     setSelectedCity(city);
//   };

//   const handleSearch = (e) => {
//     e.preventDefault();
//     navigate(`/all-products?search=${encodeURIComponent(searchQuery)}`);
//   };

//   // Callback to set login status after successful login
//   const handleLoginSuccess = () => {
//     setIsLoggedIn(true);
//     setShowModal(false); // Close the modal after login
//   };

//   return (
//     <>
//       <div className='uppernavbar-container'>
//         <Navbar className="UpperNavBar" bg="light" variant="light" expand="lg" sticky="top">
//           <Container>
//             <Navbar.Brand href="/">
//               <img
//                 src="https://i.ibb.co/tcPyngw/logo.png"
//                 alt="HelpTechi Logo"
//                 style={{ height: '40px' }}
//               />
//             </Navbar.Brand>
//             <Form className="d-flex" style={{ flexGrow: 1, marginLeft: '20px', marginRight: '20px' }} onSubmit={handleSearch}>
//               <FormControl
//                 type="search"
//                 placeholder="Search"
//                 className="me-2"
//                 aria-label="Search"
//                 value={searchQuery}
//                 onChange={(e) => setSearchQuery(e.target.value)}
//               />
//               <Button variant="outline-success" type="submit">Search</Button>
//             </Form>
//             <Nav className="ml-auto">
//               <Nav.Link href="/">Home</Nav.Link>
//               <Nav.Link href="/about">About</Nav.Link>
//               <NavDropdown title={selectedCity ? selectedCity.name : "Select City"} id="navbarScrollingDropdown">
//                 {cities.map((city) => (
//                   <NavDropdown.Item 
//                     key={city.id} 
//                     onClick={() => handleCitySelect(city)}
//                   >
//                     {city.name}
//                   </NavDropdown.Item>
//                 ))}
//               </NavDropdown>

//               {isLoggedIn ? (
//                 <Dropdown>
//                   <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
//                     <i className="fas fa-user-circle"></i> {/* Profile Icon */}
//                   </Dropdown.Toggle>
//                   <Dropdown.Menu>
//                     <Dropdown.Item href="/profile">Profile</Dropdown.Item>
//                     <Dropdown.Item href="/logout">Logout</Dropdown.Item>
//                   </Dropdown.Menu>
//                 </Dropdown>
//               ) : (
//                 <Button className="LoginButton" onClick={() => setShowModal(true)} variant="dark">Sign In</Button>
//               )}
//             </Nav>
//           </Container>
//         </Navbar>
//       </div>

//       <Modal
//         show={showModal}
//         onHide={() => setShowModal(false)}
//         centered
//         dialogClassName="custom-modal"
//       >
//         <Modal.Body>
//           <LoginRegister closeModal={() => setShowModal(false)} onLoginSuccess={handleLoginSuccess} /> {/* Pass login success callback */}
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };

// export default UpperNavBar;


// src/components/NavBar/UpperNavBar.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav, Form, FormControl, Button, NavDropdown, Container, Modal, Dropdown } from 'react-bootstrap';
import { getAllCities } from '../../api'; // Assuming you have this API call
import LoginRegister from '../LoginRegister/LoginRegister';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import './NavBar.css';

const UpperNavBar = () => {
  const [cities, setCities] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { selectedCity, setSelectedCity, user, logout } = useGlobalState(); // Access user and logout from context
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const data = await getAllCities();
        setCities(data.data);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchCities();
  }, []);

  const handleCitySelect = (city) => {
    setSelectedCity(city);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/all-products?search=${encodeURIComponent(searchQuery)}`);
  };

  // Callback to set login status after successful login
  const handleLoginSuccess = () => {
    setShowModal(false); // Close the modal after login
  };

  return (
    <>
      <div className='uppernavbar-container'>
        <Navbar className="UpperNavBar" bg="light" variant="light" expand="lg" sticky="top">
          <Container>
            <Navbar.Brand href="/">
              <img
                src="https://i.ibb.co/tcPyngw/logo.png"
                alt="HelpTechi Logo"
                style={{ height: '40px' }}
              />
            </Navbar.Brand>
            <Form className="d-flex" style={{ flexGrow: 1, marginLeft: '20px', marginRight: '20px' }} onSubmit={handleSearch}>
              <FormControl
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Button variant="outline-success" type="submit">Search</Button>
            </Form>
            <Nav className="ml-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
              <NavDropdown title={selectedCity ? selectedCity.name : "Select City"} id="navbarScrollingDropdown">
                {cities.map((city) => (
                  <NavDropdown.Item 
                    key={city.id} 
                    onClick={() => handleCitySelect(city)}
                  >
                    {city.name}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>

              {user ? ( // Check if user is logged in
                <Dropdown>
                  <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                My Account<i className="fas fa-user-circle"></i> {/* Profile Icon */}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/my-profile">View Account</Dropdown.Item>
                    <Dropdown.Item onClick={() => {
                      logout(); // Call logout from context
                      navigate('/'); // Redirect to home after logout
                    }}>Sign out</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Button className="LoginButton" onClick={() => setShowModal(true)} variant="dark">Sign In</Button>
              )}
            </Nav>
          </Container>
        </Navbar>
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Body className="modal-body">
          <LoginRegister closeModal={() => setShowModal(false)} onLoginSuccess={handleLoginSuccess} /> {/* Pass login success callback */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpperNavBar;
