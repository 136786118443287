import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllCategories } from '../../api';

const LowerNavBar = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getAllCategories();
        setCategories(response);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category.id);
    
    // If category ID is -1, navigate to all products without filters
    if (category.id === -1) {
      navigate('/all-products', { 
        state: { 
          subCategories: [], // No subcategories
          categoryId: -1, 
          categoryName: 'All Products' 
        } 
      });
    } else {
      // Navigate to the products page with all products under the selected category
      const allSubCategories = category.subCategories.map(subCat => subCat.name);
      navigate('/all-products', { 
        state: { 
          subCategories: allSubCategories, 
          categoryId: category.id, 
          categoryName: category.category 
        } 
      });
    }
  };

  const handleSubCategoryClick = (subCategory, category) => {
    // Navigate to the products page with only the products belonging to the clicked subcategory
    navigate('/all-products', { 
      state: { 
        subCategories: [subCategory.name], // Only this subcategory
        categoryId: category.id, 
        categoryName: category.category 
      } 
    });
  };

  return (
    <div className="lower-navbar">
      <div className="box-text">
        {categories.map(cat => (
          <div
            key={cat.id}
            className={`category-item ${selectedCategory === cat.id ? 'selected' : ''}`}
            onClick={() => handleCategoryClick(cat)}
            onMouseEnter={() => setHoveredCategory(cat.id)}
            onMouseLeave={() => setHoveredCategory(null)}
          >
            {cat.category}
            {hoveredCategory === cat.id && (
              <div className="subcategory-dropdown">
                {cat.subCategories.map(subCat => (
                  <div 
                    key={subCat.id} 
                    className="subcategory-dropdown-item"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the click from bubbling to the category
                      handleSubCategoryClick(subCat, cat); // Pass the category along with subcategory
                    }}
                  >
                    {subCat.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LowerNavBar;