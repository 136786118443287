import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './BreadCrumb.css';

const BreadCrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const breadcrumbNameMap = {
    'all-products': 'All Products'
  };

  if (pathnames.length === 0) {
    return null;
  }

  return (
    <nav className="breadcrumb">
      <Link to="/" className="breadcrumb-link">Home</Link>
      {pathnames.map((value, index) => {
        const isLast = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return (
          <React.Fragment key={to}>
            <span className="breadcrumb-separator">›</span>
            {isLast ? (
              <span className="breadcrumb-current">
                {breadcrumbNameMap[value] || value.replace(/-/g, ' ')}
              </span>
            ) : (
              <Link to={to} className="breadcrumb-link">
                {breadcrumbNameMap[value] || value.replace(/-/g, ' ')}
              </Link>
            )}
          </React.Fragment>
        );
      })}
    </nav>
  );
};

export default BreadCrumb;
