import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className="Footer-Total">
      <div className="footer">
      <div className="footer-upper">
        <div className="footer-section logo-section">
          <img
    src="https://i.ibb.co/dM00xCs/icon.png"
    alt="HelpTechi Logo"
    style={{ height: '150px' }}
  />
        </div>
        <div className="footer-section company-section">
          <h4>COMPANY</h4>
          <div className="footer-links">
            <a href="/">Home</a>
            <div>About Us</div>
            <div>Queries & Solutions</div>
            <div>Contact Us</div>
          </div>
        </div>
        <div className="footer-section help-section">
          <h4>Any Help?</h4>
          <div className="help-item">
            <span className="material-symbols-outlined location-icon">
              location_on
            </span>
            HelpTech HQ
          </div>
          <div className="help-item">
            <span className="material-symbols-outlined location-icon">
              mail
            </span>
            inquiries.solutions@helptechi.com
          </div>
          <div className="help-item">
            <span className="material-symbols-outlined location-icon">
              call
            </span>
            9467-586-586
          </div>
          <div className="help-item">
            <i className="fa fa-whatsapp location-icon"></i>
            9467-586-586
          </div>
        </div>
        <div className="footer-section questions-section">
          <h4>Recent Questions</h4>
          <div className="footer-links">
            <div>What is HelpTech? <span className="label free">Free</span></div>
            <div>How to clean an LED TV? <span className="label free">Free</span></div>
            <div>How to factory reset Android TV in a few seconds? <span className="label paid">Paid</span></div>
          </div>
        </div>
      </div>
     <div>
     <div className="footer-lower">
        <p>
          &copy;2024 Help Techi. All rights reserved | <a href="/terms-of-use">Terms of use</a> | <a href="/privacy-policy">Privacy policy</a> | <a href="/cancellation-refund">Cancellation and refund</a>
        </p>
        <div className="social-media">
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-instagram"></i>
          </a>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-facebook"></i>
          </a>
          <a href="https://wa.me/9467586586" target="_blank" rel="noopener noreferrer">
            <i className="fa fa-whatsapp"></i>
          </a>
        </div>
     </div>
      </div>
    </div>
    </div>
  );
};

export default Footer;
