import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ProductCard.css';

const ProductCard = ({ product }) => {
  const navigate = useNavigate();
  const seller = product.sellers.reduce((min, curr) => curr.seller_id < min.seller_id ? curr : min, product.sellers[0]);

  const handleViewProduct = () => {
    navigate(`/${product.product_sku}`);
  };

  return (
    <div className="product-card">
      <img src={product.main_image} alt={product.product_name} className="product-image" onClick={handleViewProduct} />
      <div className="product-info-product-card">
        <h3 className="product-name-product-card">{product.product_name}</h3>
        <p className="brand-name">{product.brand}</p>
        <p className="seller-info">{seller.seller_name}<br />{seller.city_name}</p>
      </div>
      <button className="view-product-btn" onClick={handleViewProduct}>View Product</button>
      {/* <button className="get-price-list-btn">Get Price List</button> */}
    </div>
  );
};

export default ProductCard;