import React from 'react';
import UpperNavBar from './UpperNavBar';
import LowerNavBar from './LowerNavBar';
import './NavBar.css';

const NavBar = () => {
  return (
    <>
     <div className="NavBar-container">
     <UpperNavBar />
     <LowerNavBar />
     </div>
    </>
  );
};

export default NavBar;
