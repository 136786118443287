import React, { useState, useEffect } from 'react';
import { Table, Container, Modal, Button } from 'react-bootstrap';
import { useGlobalState } from '../../contexts/GlobalStateContext';
import axios from 'axios'; // Import axios for API calls
import 'bootstrap/dist/css/bootstrap.min.css';
import { getPriceList } from '../../api';

const PriceList = () => {
  const { isLoggedIn } = useGlobalState(); // Get the isLoggedIn state from context
  const [showModal, setShowModal] = useState(false);
  const [products, setProducts] = useState([]); // State to hold the price list data
  const [loading, setLoading] = useState(true); // Loading state

  // Fetch the price list from the backend
  useEffect(() => {
    if (isLoggedIn) {
      const fetchPriceList = async () => {
        try {
          const fetchList = await getPriceList();
          setProducts(fetchList.data); // Set the price list data from the response
          setLoading(false); // Set loading to false when data is fetched
        } catch (error) {
          console.error('Error fetching price list', error);
          setLoading(false);
        }
      };
      fetchPriceList();
    }
  }, [isLoggedIn]);

  const handleOpenModal = () => {
    if (!isLoggedIn) {
      setShowModal(true); // Show modal if not logged in
    }
  };
  const currentDate = new Date().toLocaleDateString('en-GB');
  return (
    <Container className="my-4">
        <h2 className="mb-4">Price List ({currentDate})</h2>
      {!isLoggedIn ? (
        <Button variant="primary" onClick={handleOpenModal}>
          View Price List (Login Required)
        </Button>
      ) : loading ? (
        <p>Loading price list...</p>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Part Name</th>
              <th>Part No.</th>
              <th>Part Type</th>
              <th>Resolution</th>
              <th>Price</th>
              <th>Min Order Qty</th>         
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={index}>
                <td>{product.part_name}</td>
                <td>{product.part_no}</td>
                <td>{product.part_type}</td>
                <td>{product.resolution}</td>
                <td>{product.price.toFixed(2)}</td>
                <td>{product.min_qty}</td>
        
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {/* Modal to ask user to login */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Login Required</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please login to view the price list.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" href="/login">
            Go to Login
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default PriceList;
