import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { getAllBrands } from '../../api'; // Adjust the import path if necessary

function Section3() {
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    // Fetch brands from the API when the component mounts
    const fetchBrands = async () => {
      try {
        const brandData = await getAllBrands();
        setBrands(brandData.data);


      } catch (error) {
        console.error('Failed to fetch brands:', error);
      }
    };

    fetchBrands();
  }, []);

  return (
    <Row className="section3 mb-4">
      <Col>
        <h2>FEATURED BRANDS</h2>
        <div className="brand-logos-wrapper">
          <div className="brand-logos">
            {brands.map((brand, index) => (
              <div className="brand-logo" key={index}>
                {brand.name}
              </div>
            ))}
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Section3;
