import React from 'react';
import { Container } from 'react-bootstrap';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import './Home.css';

function Home() {
  return (
    <div className="Home-page-container">
      <Container className="home-page">
      <Section1 />
      <Section2 />
      <Section3 />
    </Container>
    </div>
  );
}

export default Home;

