import React, { useState, useEffect } from 'react';
import { Container, Col, Card, Spinner, Alert, Button } from 'react-bootstrap';
import { getAllProducts } from '../../api'; // Ensure this import path is correct
import { useNavigate } from 'react-router-dom'; // Ensure this import is correct

const FEATURED_SKUS = ["6518110002", "6518110001"];

const Section2 = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); // Initialize navigate

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await getAllProducts();
                if (response.status === 200 && Array.isArray(response.data)) {
                    const featuredProducts = response.data.filter(product => FEATURED_SKUS.includes(product.product_sku));
                    setProducts(featuredProducts);
                } else {
                    throw new Error('Invalid data format');
                }
            } catch (error) {
                setError('Failed to load products.');
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, []);

    const handleViewProduct = (productName) => {
        navigate(`/${productName}`); // Updated path to match new route structure
    };

    if (loading) {
        return (
            <Container className="section2-container">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="section2-container">
                <Alert variant="danger">{error}</Alert>
            </Container>
        );
    }

    return (
        <Container className="section2-container">
            <h2 className="section2-title">FEATURED PRODUCTS</h2>
            {products.length > 0 ? (
                <div className="featured-product-card-container">
                    {products.map((product) => {
                        const topSeller = product.sellers.reduce((prev, current) => (prev.seller_id < current.seller_id ? prev : current), product.sellers[0]);
                        return (
                            <Col md={3} key={product.product_sku}> {/* Use product_sku as key */}
                                <Card className="featured-product-card">
                                    <div 
                                        className="featured-product-image-container"
                                        onClick={() => handleViewProduct(product.product_sku)} // Handle image click
                                    >
                                        <img 
                                            src={product.main_image || 'https://via.placeholder.com/150'} 
                                            alt={product.product_sku || 'Product Image'} 
                                            className="featured-product-image" 
                                        />
                                    </div>
                                    <Card.Body>
                                        <Card.Title className="featured-product-title">
                                            {product.product_name || 'Product Name'}
                                        </Card.Title>
                                        <div className="featured-seller-info">
                                            <p className="featured-seller-name">{topSeller.seller_name || 'Unknown Seller'}</p>
                                            <p className="featured-seller-city">{topSeller.city_name || 'Unknown City'}</p>
                                        </div>
                                        <div className="featured-button-group">
                                            <Button 
                                                variant="dark" 
                                                className="featured-view-product-btn"
                                                onClick={() => handleViewProduct(product.product_sku)} // Handle button click
                                            >
                                                View Product
                                            </Button>
                                            {/* <Button variant="dark" className="featured-price-list-btn">Get Price List</Button> */}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        );
                    })}
                </div>
            ) : (
                <Col>
                    <p>No featured products available</p>
                </Col>
            )}
        </Container>
    );
};

export default Section2;