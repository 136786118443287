// src/components/MyProfile/MyProfile.js
import React, { useState } from 'react';
import EditProfile from './EditProfile';
import Subscription from './Subscription';

function MyProfile() {
  const [activeTab, setActiveTab] = useState('editProfile');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      {/* <h1>My Profile</h1> */}
      <div className="nav nav-tabs">
        <button
          className={`nav-link ${activeTab === 'editProfile' ? 'active' : ''}`}
          onClick={() => handleTabChange('editProfile')}
        >
          My Profile
        </button>
        <button
          className={`nav-link ${activeTab === 'subscription' ? 'active' : ''}`}
          onClick={() => handleTabChange('subscription')}
        >
          Subscription
        </button>
      </div>

      <div className="tab-content">
        {activeTab === 'editProfile' && <EditProfile />}
        {activeTab === 'subscription' && <Subscription />}
      </div>
    </div>
  );
}

export default MyProfile;


// import React, { useState } from 'react';
// import { Container, Row, Col, Nav } from 'react-bootstrap';
// import EditProfile from './EditProfile';
// import Subscription from './Subscription';

// const MyProfile = () => {
//   const [activeTab, setActiveTab] = useState('editProfile');

//   const handleSelect = (selectedKey) => {
//     setActiveTab(selectedKey);
//   };

//   return (
//     <Container fluid>
//       <Row>
//         <Col xs={3} className="bg-light">
//           <Nav variant="pills" className="flex-column" onSelect={handleSelect}>
//             <Nav.Item>
//               <Nav.Link eventKey="editProfile">Edit Profile</Nav.Link>
//             </Nav.Item>
//             <Nav.Item>
//               <Nav.Link eventKey="subscription">Subscription</Nav.Link>
//             </Nav.Item>
//           </Nav>
//         </Col>
//         <Col xs={9}>
//           {activeTab === 'editProfile' && <EditProfile />}
//           {activeTab === 'subscription' && <Subscription />}
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default MyProfile;
