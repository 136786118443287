import React from 'react';
import './About.css'; // You can style your About page in this CSS file.

const About = () => {
  return (
    <div className="about-container">
      <h1>About Us</h1>
      <p>
        Welcome to our e-commerce platform! We offer a wide range of high-quality products tailored to meet your needs. Our mission is to provide excellent customer service and ensure that you have a seamless shopping experience.
      </p>
      <p>
        Our team is dedicated to bringing you the latest and greatest products at competitive prices. Whether you're looking for electronics, fashion, home goods, or more, we've got you covered.
      </p>
      <p>
        Thank you for choosing us. We look forward to serving you!
      </p>
    </div>
  );
};

export default About;
