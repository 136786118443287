import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { getAllProducts, getAllBrands } from '../../api';
import ProductCard from './ProductCard';
import './FullCatalogue.css';
import { useGlobalState } from '../../contexts/GlobalStateContext';

const FullCatalogue = () => {
  const location = useLocation();
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [filters, setFilters] = useState({
    selectedBrands: [],
    selectedSubCategories: location.state?.subCategories || []
  });

  // Get selected city from global state
  const { selectedCity } = useGlobalState();
  
  // Extract category name from location state
  const categoryName = location.state?.categoryName || 'All Products';

  const getSearchProducts = useMemo(() => (products, searchQuery) => {
    const searchLower = searchQuery.toLowerCase();
    return products.filter(product => {
      const nameMatches = product.product_name.toLowerCase().includes(searchLower);
      const brandMatches = product.brand.toLowerCase().includes(searchLower);
      const sellerMatches = product.sellers.some(seller => seller.seller_name.toLowerCase().includes(searchLower));
      const subcategoryMatches = product.sub_category.toLowerCase().includes(searchLower);
      
      return nameMatches || brandMatches || sellerMatches || subcategoryMatches;
    });
  }, []);

  const getFilterByBrands = useMemo(() => (products, selectedBrands) => {
    return selectedBrands.length
      ? products.filter(product => selectedBrands.includes(product.brand))
      : products;
  }, []);

  const getFilterByCities = useMemo(() => (products, city) => {
    if (!city) return products;
    return products.filter(product => product.sellers.some(seller => seller.city_name === city));
  }, []);

  const filterBySubCategories = useCallback((products, subCategories) => {
    if (!subCategories || subCategories.length === 0) return products;
    return products.filter(product => subCategories.includes(product.sub_category));
  }, []);

  useEffect(() => {
    const fetchFilters = async () => {
      try {
        const fetchedBrands = await getAllBrands();
        setBrands(fetchedBrands.data);
      } catch (error) {
        console.error('Error fetching filters:', error);
      }
    };

    fetchFilters();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await getAllProducts();
        const allProducts = response.data || response;

        if (!Array.isArray(allProducts)) {
          console.error('Unexpected API response format:', allProducts);
          setFilteredProducts([]);
          return;
        }

        const queryParams = new URLSearchParams(location.search);
        const searchQuery = queryParams.get('search') || '';
        const stateSubCategories = location.state?.subCategories || [];

        // If categoryId is -1, set filteredProducts to all products
        if (location.state?.categoryId === -1) {
          setFilteredProducts(allProducts);
          return;
        }

        // Apply filters
        const searchFilteredProducts = getSearchProducts(allProducts, searchQuery);
        const subCategoryFilteredProducts = filterBySubCategories(searchFilteredProducts, stateSubCategories.length ? stateSubCategories : filters.selectedSubCategories);
        const brandFilteredProducts = getFilterByBrands(subCategoryFilteredProducts, filters.selectedBrands);
        const cityFilteredProducts = getFilterByCities(brandFilteredProducts, selectedCity?.name); // Filter by city

        setFilteredProducts(cityFilteredProducts);
      } catch (error) {
        console.error('Error fetching products:', error);
        setFilteredProducts([]);
      }
    };

    fetchProducts();
  }, [
    location.search, 
    filters.selectedBrands, 
    filters.selectedSubCategories, 
    selectedCity, 
    getSearchProducts, 
    getFilterByBrands, 
    getFilterByCities, 
    filterBySubCategories,
    location.state?.subCategories,
    location.state?.categoryId // Add this to the dependency array
  ]);

  const handleFiltersChange = useCallback(() => {
    setFilters(prevFilters => ({
      ...prevFilters,
      selectedBrands: brands.filter(brand => selectedBrands.includes(brand.id)).map(brand => brand.name)
    }));
  }, [selectedBrands, brands]);

  useEffect(() => {
    handleFiltersChange();
  }, [selectedBrands, handleFiltersChange]);

  const handleBrandSelect = (brandId) => {
    setSelectedBrands(prevSelectedBrands => 
      prevSelectedBrands.includes(brandId)
        ? prevSelectedBrands.filter(id => id !== brandId)
        : [...prevSelectedBrands, brandId]
    );
  };

  return (
    <div className="full-catalogue">
      <div className="filters">
        <h3>Filters</h3>

        <div className="filter-section">
          <div className="filter-heading">
            <h4>Brands</h4>
          </div>
          <Form className="filter-options">
            {brands.map((brand) => (
              <Form.Check 
                key={brand.id} 
                type="checkbox" 
                label={brand.name} 
                className="brand-label"
                checked={selectedBrands.includes(brand.id)}
                onChange={() => handleBrandSelect(brand.id)}
              />
            ))}
          </Form>
        </div>
      </div>

      <div className="products-grid-container">
        <h3 className="products-grid-heading">{categoryName}</h3>
        <div className="products-grid">
          {filteredProducts.length > 0 ? (
            filteredProducts.map(product => (
              <ProductCard key={product.product_sku} product={product} />
            ))
          ) : (
            <p>No products found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FullCatalogue;