import React, { useState } from 'react';
import { Card, Form, Button, Row, Col, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { FaEdit } from 'react-icons/fa';

const EditProfile = () => {
  const [editingField, setEditingField] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [fieldToEdit, setFieldToEdit] = useState('');
  const [tempData, setTempData] = useState({
    name: "John Doe",
    address: "123 Main St, City, Country",
    email: "john.doe@example.com",
    phone: "+1234567890",
    shopName: "John's Shop",
    gstNumber: "1234GST5678"
  });

  const handleEditClick = (field) => {
    setFieldToEdit(field);
    setShowConfirmModal(true);
  };

  const handleConfirmEdit = () => {
    setEditingField(fieldToEdit);
    setShowConfirmModal(false);
  };

  const handleCancelEdit = () => {
    setShowConfirmModal(false);
    setFieldToEdit('');
  };

  const handleFieldChange = (e) => {
    setTempData({ ...tempData, [editingField]: e.target.value });
  };

  const handleSaveChanges = () => {
    setEditingField(null);
  };

  const renderField = (label, field) => (
    <Row className="mb-3">
      <Col sm={4}><strong>{label}:</strong></Col>
      <Col sm={6}>
        {editingField === field ? (
          <Form.Control
            type="text"
            value={tempData[field]}
            onChange={handleFieldChange}
          />
        ) : (
          <span>{tempData[field]}</span>
        )}
      </Col>
      <Col sm={2}>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-${field}`}>Edit {label}</Tooltip>}
        >
          <Button
            variant="link"
            onClick={() => handleEditClick(field)}
          >
            <FaEdit />
          </Button>
        </OverlayTrigger>
      </Col>
    </Row>
  );

  return (
    <Card>
      {/* <Card.Header><h2> </h2></Card.Header> */}
      <Card.Body>
        {renderField("Name", "name")}
        {renderField("Address", "address")}
        {renderField("E-mail", "email")}
        {renderField("Phone number", "phone")}
        {renderField("Shop name", "shopName")}
        {renderField("GST number", "gstNumber")}
        {editingField && (
          <Button
            variant="primary"
            onClick={handleSaveChanges}
            className="mt-3"
          >
            Save Changes
          </Button>
        )}
      </Card.Body>

      {/* Confirmation Modal */}
      <Modal show={showConfirmModal} onHide={handleCancelEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Edit</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to edit this field? Unsaved changes will be lost if you switch fields.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelEdit}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmEdit}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default EditProfile;
