import React, { useState } from 'react';
import { Container, Table, Button, Alert, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

const Subscription = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);

  // Mock data for subscriptions
  const subscriptions = [
    { id: 1, name: 'Basic Plan', cost: '$10.00', startDate: '2024-01-15', endDate: '2024-07-15' },
    { id: 2, name: 'Premium Plan', cost: '$25.00', startDate: '2024-02-20', endDate: '2024-08-20' },
    { id: 3, name: 'Ultimate Plan', cost: '$50.00', startDate: '2024-03-10', endDate: '2024-09-10' },
  ];

  // Handle cancel button click
  const handleCancelClick = (id) => {
    setSelectedSubscriptionId(id);
    setShowModal(true);
  };

  // Handle confirm cancel
  const handleConfirmCancel = () => {
    if (selectedSubscriptionId !== null) {
      console.log(`Cancel subscription with ID: ${selectedSubscriptionId}`);
      alert(`Subscription with ID ${selectedSubscriptionId} has been canceled.`);
      // Reset state and close modal
      setSelectedSubscriptionId(null);
      setShowModal(false);
    }
  };

  // Handle close modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedSubscriptionId(null);
  };

  // Check if there are any subscriptions
  const hasSubscriptions = subscriptions.length > 0;

  return (
    <Container className="py-5">
      <h2 className="text-center mb-4">Your Subscriptions</h2>
      {hasSubscriptions ? (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Subscription Name</th>
              <th>Cost</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th></th> {/* Empty heading for the actions column */}
            </tr>
          </thead>
          <tbody>
            {subscriptions.map(subscription => (
              <tr key={subscription.id}>
                <td>{subscription.id}</td>
                <td>{subscription.name}</td>
                <td>{subscription.cost}</td>
                <td>{subscription.startDate}</td>
                <td>{subscription.endDate}</td>
                <td>
                  <Button 
                    variant="danger" 
                    onClick={() => handleCancelClick(subscription.id)}
                  >
                    Cancel
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Alert variant="info">
          You have no active subscriptions at the moment.
        </Alert>
      )}

      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Cancellation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel this subscription? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="danger" onClick={handleConfirmCancel}>
            Cancel Subscription
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Subscription;
