import React from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './ProductPage.css';

const SellerList = ({ show, onHide, sellers, onSelectSeller }) => {
  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Other Sellers</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ListGroup>
          {sellers.map((seller) => (
            <ListGroup.Item 
              key={seller.seller_id} 
              className="seller-list-item"
              onClick={() => onSelectSeller(seller)}
              style={{ cursor: 'pointer' }} // Ensure it looks clickable
            >
              <div className="seller-name"><strong>{seller.seller_name}</strong></div>
              <div className="seller-city">{seller.city_name}</div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SellerList.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  sellers: PropTypes.arrayOf(
    PropTypes.shape({
      seller_id: PropTypes.number.isRequired,
      seller_name: PropTypes.string.isRequired,
      city_name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onSelectSeller: PropTypes.func.isRequired,
};

export default SellerList;
