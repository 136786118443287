import axios from 'axios';
import config from './config'


const BASE_URL = config.baseUrl;

// Function to get all products
export const getAllProducts = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/products`);
    return response.data;
  } catch (error) {
    console.error('Error fetching all products:', error);
    throw error;
  }
};

// Function to get product details by ID
export const getProductById = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/product/${id}`);
    return response.data.data;
  } catch (error) {
    console.error(`Error fetching product with ID ${id}:`, error);
    throw error;
  }
};

// Function to get product details by name
export const getProductByName = async (name) => {
  try {
    const response = await axios.get(`${BASE_URL}/products`);
    const products = response.data.data; // Access the 'data' property containing the array of products
    
    if (Array.isArray(products)) {
      const product = products.find(product => product.product_name === name);
      if (product) {
        return product;
      } else {
        throw new Error(`Product with name "${name}" not found.`);
      }
    } else {
      throw new Error('Response data is not an array.');
    }
  } catch (error) {
    console.error(`Error fetching product with name "${name}":`, error);
    throw error;
  }
};


// Function to get all cities
export const getAllCities = async () => {
  try {
    console.log(`URL is $${BASE_URL}/cities`);
    const response = await axios.get(`${BASE_URL}/cities`);
    return response.data;
  } catch (error) {
    console.error('Error fetching all cities:', error);
    throw error;
  }
};

// Function to get all categories
export const getAllCategories = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/categories`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching all categories:', error);
    throw error;
  }
};

// Function to get all brands
export const getAllBrands = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/brands`);
    return response.data;
  } catch (error) {
    console.error('Error fetching all brands:', error);
    throw error;
  }
};

// Other API functions can be added here similarly

// Function to filter products by cities
export const getFilterByCities = (products, selectedCities) => {
  return selectedCities.length
    ? products.filter(product => selectedCities.includes(product.city_name))
    : products;
};

export const getPriceList = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/price-list`);
    return response.data;
  } catch (error) {
    console.error('Error fetching price list:', error);
    throw error;
  }
};
