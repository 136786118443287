// // src/contexts/GlobalStateContext.js
// import React, { createContext, useState, useContext } from 'react';

// // Create a Context
// const GlobalStateContext = createContext();

// // Create a provider component
// export const GlobalStateProvider = ({ children }) => {
//   const [selectedCity, setSelectedCity] = useState(null);

//   return (
//     <GlobalStateContext.Provider value={{ selectedCity, setSelectedCity }}>
//       {children}
//     </GlobalStateContext.Provider>
//   );
// };

// // Custom hook for using context
// export const useGlobalState = () => {
//   return useContext(GlobalStateContext);
// };


// src/contexts/GlobalStateContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';

// Create a Context
const GlobalStateContext = createContext();

// Create a provider component
export const GlobalStateProvider = ({ children }) => {
  const [selectedCity, setSelectedCity] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  // On mount, check if the user is already logged in by looking in localStorage
  useEffect(() => {
    const token = localStorage.getItem('userToken');
    const storedUser = localStorage.getItem('user');
    if (token && storedUser) {
      setIsLoggedIn(true);
      setUser(JSON.parse(storedUser)); // Parse the user data
    }
  }, []);

  // Login function
  const login = (userData, token) => {
    setIsLoggedIn(true);
    setUser(userData);
    localStorage.setItem('userToken', token);
    localStorage.setItem('user', JSON.stringify(userData));
  };

  // Logout function
  const logout = () => {
    setIsLoggedIn(false);
    setUser(null);
    localStorage.removeItem('userToken');
    localStorage.removeItem('user');
  };

  return (
    <GlobalStateContext.Provider value={{
      selectedCity,
      setSelectedCity,
      isLoggedIn,
      user,
      login,
      logout,
    }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

// Custom hook for using context
export const useGlobalState = () => {
  return useContext(GlobalStateContext);
};
